//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-80:_6691,_3936,_5408,_8276,_9712,_6140,_5684,_2408;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-80')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-80', "_6691,_3936,_5408,_8276,_9712,_6140,_5684,_2408");
        }
      }catch (ex) {
        console.error(ex);
      }